import React from 'react';
import { ColumnLayout, Spinner, Link as PolarisLink, Container, Header } from '@amzn/awsui-components-react/polaris';
import { Broker, Ec2Instance, Ec2Volume, Link } from '../../../types';
import { renderField, renderLink, renderElement } from '../../Components/tableUtils';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { createIsengardLink } from '../../../utils';

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[] | undefined
}

function renderNodeMapping(brokerInstances: Ec2Instance[] | undefined): React.ReactNode {
    if (brokerInstances === undefined) {
        return <Spinner />
    } else {
        let ret: React.ReactElement[] = [];
        for (let i = 0; i < brokerInstances.length; i++) {
            ret.push(<p>{brokerInstances[i].instanceId + " --> " + brokerInstances[i].privateIp}</p>);
        }
        return ret;
    }
}

function brokerNodeMappingCopyText(brokerInstances: Ec2Instance[] | undefined): string {
    if (brokerInstances) {
        return brokerInstances
            .map(brokerInstance => `${brokerInstance.instanceId} --> ${brokerInstance.privateIp}`)
            .join("\n");
    }
    return "Node Mapping not available";
}

function createEBSDataPartitionLink(brokerInstances: Ec2Instance[]): Link | undefined {
    if (brokerInstances === undefined) {
        return undefined;
    }

    if (brokerInstances.length === 0) {
        return undefined;
    }

    let firstInstance = brokerInstances[0];
    let volumes : Ec2Volume[] = firstInstance.volumes.filter(v => v.size != 32);
    if (volumes.length === 0) {
        return undefined;
    }
    let firstVolume = volumes[0];
    return {
        name: firstVolume.volumeId + " (" + firstVolume.size + " GB)",
        href: firstVolume.ebsStatsLink
    }
}

function createEBSRootPartitionLink(brokerInstances: Ec2Instance[]): Link | undefined {
    if (brokerInstances === undefined) {
        return undefined;
    }

    if (brokerInstances.length === 0) {
        return undefined;
    }

    let firstInstance = brokerInstances[0];
    let volumes : Ec2Volume[] = firstInstance.volumes;
    if (volumes.length === 0) {
        return undefined;
    }
    let firstVolume = volumes[0];
    return {
        name: firstVolume.volumeId + " (" + firstVolume.size + " GB)",
        href: firstVolume.ebsStatsLink
    }
}

function renderDDB(broker: Broker): React.ReactNode {
    let destination = `dynamodbv2/home?region=${broker.regionName}#edit-item?itemMode=2&pk=${broker.id}&route=ROUTE_ITEM_EXPLORER&sk=&table=Brokers`;
    let readOnlyIsenLink = createIsengardLink(broker.brokerInfo.controlPlaneAccountId, 'ReadOnly', destination);
    let amazonMqOpsIsenLink =  createIsengardLink(broker.brokerInfo.controlPlaneAccountId, 'AmazonMqOps', destination);
    return (
        <div style={{display: "flex", gap: 8, alignItems: 'center', alignContent: 'center'}}>
            <div>Brokers Entry</div>
            <div style={{display: "flex", gap: 2, alignItems: 'center'}}><PolarisLink href={readOnlyIsenLink} target='_blank'>ReadOnly</PolarisLink><CopyToClipboardButton text={readOnlyIsenLink} textDescription='ReadOnly link'/></div>
            <div style={{display: "flex", gap: 2, alignItems: 'center'}}><PolarisLink href={amazonMqOpsIsenLink} target='_blank'>AmazonMqOps</PolarisLink><CopyToClipboardButton text={amazonMqOpsIsenLink} textDescription='AmazonMqOps link'/></div>
        </div>
    )
}

function renderCloudwatchLogsInsightsLink(broker: Broker): React.ReactNode {
    const linkFragment = `cloudwatch/home?region=${broker.regionName}#logsV2:logs-insights`
    const readOnlyIsenLink = createIsengardLink(broker.brokerInfo.cellAccountId, 'ReadOnly', linkFragment);
    return (
        <div>
            <PolarisLink href={readOnlyIsenLink} target='_blank'>ReadOnly</PolarisLink>
            <CopyToClipboardButton text={readOnlyIsenLink} textDescription='ReadOnly link'/>
        </div>
    )
}

const BrokerInfoTable : React.FC<Props> = ({broker, brokerInstances}) => {

    const brokerInfo = broker.brokerInfo;

    let content : React.ReactElement[] = [];
    content.push(renderField("ARN", brokerInfo.arn));
    content.push(renderField("Creation Date", brokerInfo.creationDate));
    content.push(renderField("Publicly Accessible", brokerInfo.publiclyAccessible ? "true" : "false"));
    content.push(renderField("CodeDeploy", brokerInfo.codeDeploy ? brokerInfo.codeDeploy : "none"));
    content.push(renderLink("CodeDeploy Logs", brokerInfo.codeDeployLogsLink.name, brokerInfo.codeDeployLogsLink.href));
    if (brokerInfo.cfnStackLink !== undefined && brokerInfo.cfnStackLink !== null) {
        content.push(renderLink("CFN Stack", brokerInfo.cfnStackLink.name, brokerInfo.cfnStackLink.href, brokerInfo.cfnStackLink.name));
    } else {
        content.push(renderField("CFN Stack", "none"));
    }
    if (brokerInfo.efsInfo) {
        content.push(renderField("EFS data partition", brokerInfo.efsInfo));
    }

    if (brokerInstances === undefined) {
        content.push(renderElement("EBS data partition", <Spinner />, ""));
        content.push(renderElement("EBS root partition", <Spinner />, ""));
    } else {
        let ebsDataPartitionLink: Link | undefined = createEBSDataPartitionLink(brokerInstances);

        if (ebsDataPartitionLink !== undefined) {
            content.push(renderLink("EBS data partition", ebsDataPartitionLink.name, ebsDataPartitionLink.href, ebsDataPartitionLink.href));
        } else {
            content.push(renderField("EBS data partition", "None"));
        }
        let ebsRootPartitionLink: Link | undefined = createEBSRootPartitionLink(brokerInstances);

        if (ebsRootPartitionLink !== undefined) {
            content.push(renderLink("EBS root partition", ebsRootPartitionLink.name, ebsRootPartitionLink.href, ebsRootPartitionLink.href));
        } else {
            content.push(renderField("EBS root partition", "None"));
        }
    }
    
    content.push(renderField("Engine", brokerInfo.engine, "Pending: " + brokerInfo.pendingEngine));
    content.push(renderField("Pending Host Instance Type", brokerInfo.pendingHostInstanceType));
    content.push(renderField("EncryptionOptions", brokerInfo.encryptionOptions));
    content.push(renderLink("Cell ID", brokerInfo.cellId, brokerInfo.cellIsenLink, "Cell Link"));
    content.push(renderField("Cell Account ID", brokerInfo.cellAccountId));
    content.push(renderField("Authentication Strategy", brokerInfo.authenticationStrategy));
    content.push(renderLink("Control Plane Account ID", brokerInfo.controlPlaneAccountId, brokerInfo.controlPlaneAccountIsenLink, "Control Plane Account ID"));

    content.push(renderElement("Node Mapping", renderNodeMapping(brokerInstances), brokerNodeMappingCopyText(brokerInstances)));

    content.push(renderElement("DDB", renderDDB(broker), ""));
    content.push(renderElement("Cloudwatch Logs Insights", renderCloudwatchLogsInsightsLink(broker), ""));

    return (
        <Container header={
            <Header variant="h2">
                Broker Info
            </Header>
        }>
            <ColumnLayout columns={3} variant="text-grid">
                {content}
            </ColumnLayout>
        </Container>
    )
}

export default BrokerInfoTable;