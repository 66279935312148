import React, { useEffect, useState } from 'react';
import { Alert, Flashbar, Header, Link, Table, TextFilter } from '@amzn/awsui-components-react/polaris';
import { getRecentWorkflows } from '../../../api/api';
import { Broker, Workflow } from '../../../types';
import { buildOldConsoleUrl } from '../../../utils';

type Props = {
    broker: Broker,
}  

const Workflows : React.FC<Props> = ({broker}) =>  {


    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [filteringText, setFilteringText] = useState<string>("");

    useEffect(() => {
        setLoadingData(true);
        setError("");
        getRecentWorkflows(broker.id, broker.snapshotId).then(response => {
            setWorkflows(response.workflows);
        }).catch(error => {
            setError("Could not load workflows.");
            console.error(error);
        }).finally(() => {
            setLoadingData(false)
        })
    }, []);

    function getCodeDeployments(codeDeploymentLinks: any[]) {
        return codeDeploymentLinks.map(function(deployment: any) {
            return <div key={deployment["id"]}><a href={deployment["link"]} target="_blank">{deployment["id"]}</a><br></br></div>;
        })
    }

    let columnDefinitions = [
        {
            id: "workflowName",
            header: "Workflow",
            cell: (item: any) => item.workflowName,
            sortingField: 'workflowName'
        },
        {
            id: "maintenance",
            header: "Maintenance",
            cell: (item: any) => item.executionArn.includes("Workflow:mw-") ? "true" : "false"
        },
        {
            id: "name",
            header: "StepFunctions",
            cell: (item: any) => <Link href={item.link} target="_blank">StepFunctions Link</Link>
        },
        {
            id: "status",
            header: "Status",
            cell: (item: any) => item.status
        },
        {
            id: "codeDeploy",
            header: "Code Deployments",
            cell: (item: any) => Object.keys(item.codeDeploymentLinks).length !== 0 ? getCodeDeployments(item.codeDeploymentLinks) : "none"

        },
        {
            id: "cfnStack",
            header: "CloudFormation Stack",
            cell: (item: any) => <Link href={item.cfnStackLink} target="_blank">CloudFormation Link</Link>

        },
        {
            id: "startTime",
            header: "Start Time",
            cell: (item: any) => item.startTime,
            sortingField: 'startTime'
        },
        {
            id: "endTime",
            header: "End Time",
            cell: (item: any) => item.endTime,
            sortingField: 'endTime'
        }
    ]

    return (
        <div className="infoTable" style={{display: 'flex', flexDirection: 'column', gap: 8}}>
             {error && <Flashbar items={
                                [{
                                    "type": "error",
                                    "dismissible": false,
                                    "content": error
                                }]
                            }></Flashbar>}
            <Alert type='info'>This feature is not ready yet, use old console to see broker workflows</Alert>
            <Table
                items={workflows.filter(w => w.workflowName && w.workflowName.toLowerCase().includes(filteringText.toLowerCase()))}
                filter={
                    <TextFilter
                        filteringPlaceholder="Search Workflow"
                        filteringText={filteringText}
                        onChange={(event) => {
                            setFilteringText(event.detail.filteringText);
                        }}
                    />
                }
                columnDefinitions={columnDefinitions}
                loading={loadingData}
                wrapLines={true}
                empty="No Workflows found"
                sortingColumn={{
                    sortingField: 'endTime'
                }}
                sortingDescending={true}
            />
        </div>
    )

}

export default Workflows;
